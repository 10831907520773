windIcon = L.divIcon
  iconSize: [
    26
    48
  ]
  popupAnchor: [
    1
    -10
  ]
  iconAnchor: [
    13
    12
  ]

L.WindMarker = L.Marker.extend(
  options:
    speed: 0
    heading: 0
    title: ''
    icon: windIcon
  _setPos: (pos) ->
    L.Marker::_setPos.call this, pos
    rotate = 'rotate(' + @options.heading + 'deg)'
    colorIndex = Math.floor(@options.speed / 5)
    if colorIndex > 15
      colorIndex = 15
    colorX = -colorIndex * 26
    @_icon.style['background'] = 'url("/img/map-wind-icon.png")'
    @_icon.style['border'] = 'none'
    @_icon.style['background-position'] = colorX + 'px 0px'
    @_icon.style[L.DomUtil.TRANSFORM] += ' ' + rotate
    @_icon.style[L.DomUtil.TRANSFORM + '-origin'] = '13px 10px'
    @_icon.title = @options.title
)

L.windMarker = (pos, options) ->
  new (L.WindMarker)(pos, options)
  